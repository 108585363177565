import { TFunction } from 'i18next';
import { find } from 'lodash';
import React from 'react';
import { CellProps } from 'react-table';
import { AuthenticatedApiRequest } from '../../../models/apiRequest';
import { ById } from '../../../models/id';
import { Link } from '../../../shared/navigation/Link';
import { HasOneOfPermissions } from '../../../shared/Permissions';
import { useFiltering, UseFilteringResult } from '../../../shared/tables/filtering/useFiltering';
import { PagedRequest } from '../../../shared/tables/pagination/pagination';
import { styled } from '../../../styling/theme';
import { PmsTypeResponse } from '../../authentication/loginData/metadata';
import { permissions } from '../../authentication/permissions';
import { MerlinGroup } from '../merlinGroups/merlinGroup';
import { getPmsInstanceCodeFromFilterOption, PmsInstanceByCode } from '../pms/pmsSite';
import { PracticeGroupResponse } from '../practiceGroups/practiceGroup';
import { SiteFilterOptions, SiteResponse, SitesResponse } from '../site';
import { getSites } from '../../../api/sitesApi';
import { editSiteUrl } from '../siteUrls';

type SitesTableProps = {
  pmsTypes: Array<PmsTypeResponse>;
  merlinGroups: Array<MerlinGroup>;
};

export const sitesPageSize = 10;

export type SitesBaseProps = {
  pmsInstancesByCode: PmsInstanceByCode;
  practiceGroupsById: ById<PracticeGroupResponse>;
};

export const SitesNameCellContainer = styled.div`
  min-width: 400px;
  overflow-wrap: break-word;
`;

const SitesIdCellContainer = styled.div`
  min-width: 50px;
`;

const SitesPmsTypeCellContainer = styled.div`
  min-width: 180px;
`;

const SitesPracticeGroupsCellContainer = styled.div`
  min-width: 250px;
`;

export const SitesFullWidthTableContainer = styled.div`
  min-width: 750px;
`;

export const SitesNameCell = ({ cell }: CellProps<SiteResponse>) => (
  <SitesNameCellContainer>
    <HasOneOfPermissions
      permissions={[permissions.SuperAdmin, permissions.CentralAdmin, permissions.SitePriceAdmin]}
    >
      {hasPermission => {
        if (hasPermission) {
          return (
            <Link to={editSiteUrl((cell.row.original as SiteResponse).siteId)}>{cell.value}</Link>
          );
        } else {
          return cell.value;
        }
      }}
    </HasOneOfPermissions>
  </SitesNameCellContainer>
);

export const getSiteColumns = (props: SitesTableProps, t: TFunction) => [
  {
    Header: t<string>('siteList.siteId'),
    accessor: (row: SiteResponse) => row.siteId,
    id: 'siteId',
    Cell: ({ cell }: CellProps<SiteResponse>) => (
      <SitesIdCellContainer>{cell.value}</SitesIdCellContainer>
    ),
  },
  {
    Header: t<string>('siteList.pmsSiteId'),
    accessor: (row: SiteResponse) => row.sitePmsSiteId,
    id: 'pmsSiteId',
    Cell: ({ cell }: CellProps<SiteResponse>) => (
      <SitesIdCellContainer>{cell.value}</SitesIdCellContainer>
    ),
  },
  {
    Header: t<string>('siteList.nameColumnHeader'),
    accessor: (row: SiteResponse) => row.siteName,
    id: 'siteName',
    Cell: SitesNameCell,
  },
  {
    Header: t<string>('siteList.pmsTypeColumnHeader'),
    accessor: (row: SiteResponse) => {
      if (row.merlinGroupId != null) {
        const merlinGroup = find(props.merlinGroups, group => group.groupId === row.merlinGroupId);
        return merlinGroup?.name ?? '';
      }

      const pmsType = find(props.pmsTypes, type => type.code === row.sitePmsTypeCode);
      return pmsType ? t(`metadata:${pmsType.translationKey}`) : '';
    },
    id: 'sitePmsType',
    Cell: ({ cell }: CellProps<SiteResponse>) => (
      <SitesPmsTypeCellContainer>{cell.value}</SitesPmsTypeCellContainer>
    ),
  },
  {
    Header: t<string>('siteList.practiceGroupsColumnHeader'),
    accessor: (row: SiteResponse) => row.sitePracticeGroups.map(spg => spg.name).join(', '),
    id: 'practiceGroups',
    Cell: ({ cell }: CellProps<SiteResponse>) => (
      <SitesPracticeGroupsCellContainer>{cell.value}</SitesPracticeGroupsCellContainer>
    ),
  },
];

export const initialSiteFilterOptions: SiteFilterOptions = {
  searchText: null,
  pmsInstances: null,
  practiceGroupsIds: null,
};

export const urlSitesToFilterSites = (
  pmsInstancesByCode: PmsInstanceByCode,
  practiceGroupsById: ById<PracticeGroupResponse>,
) => (urlState?: Partial<SiteFilterOptions>): SiteFilterOptions => {
  if (urlState === undefined) {
    return initialSiteFilterOptions;
  }

  return {
    searchText: urlState.searchText ?? null,
    pmsInstances:
      urlState.pmsInstances?.filter(
        instance => pmsInstancesByCode[getPmsInstanceCodeFromFilterOption(instance)] != null,
      ) ?? null,
    practiceGroupsIds:
      urlState.practiceGroupsIds?.filter(id => practiceGroupsById[id] != null) ?? null,
  };
};

export const filterSitesToUrlSites = (
  filterOptions?: SiteFilterOptions,
): Partial<SiteFilterOptions> => {
  if (filterOptions === undefined) {
    return {};
  }

  return {
    searchText: filterOptions.searchText ?? undefined,
    pmsInstances: filterOptions.pmsInstances ?? undefined,
    practiceGroupsIds: filterOptions.practiceGroupsIds ?? undefined,
  };
};

export type UseSiteTableBaseStateResult = UseFilteringResult<
  SiteFilterOptions,
  AuthenticatedApiRequest<SitesResponse, PagedRequest>
>;

export const useSiteTableBaseState = (props: SitesBaseProps): UseSiteTableBaseStateResult =>
  useFiltering({
    getData: getSites,
    initialUrlState: filterSitesToUrlSites(initialSiteFilterOptions),
    urlStateToFilterOptions: urlSitesToFilterSites(
      props.pmsInstancesByCode,
      props.practiceGroupsById,
    ),
    filterOptionsToUrlState: filterSitesToUrlSites,
    pageName: 'sites',
  });
